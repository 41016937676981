import React from 'react';
import { graphql, Link } from 'gatsby';

import { linkResolver, formatDate, extractExcerpt } from '../utils';

import { Layout, TitleAndMeta, Archive, NoteItem } from '../components';

export default ({ data }) => {
  const notes = data.prismic.allNotes.edges;
  return (
    <Layout>
      <TitleAndMeta title="Notes" />
      <Archive title="Notes">
        <ul>
          {notes.map(({ node: note }) => {
            const [excerpt, longer] = extractExcerpt(note.content);
            return (
              <NoteItem
                key={note._meta.uid}
                title={note.title[0].text}
                to={linkResolver(note)}
                date={formatDate(note.publication_date)}
                showExcerpt={longer}
              >
                {excerpt}
              </NoteItem>
            );
          })}
        </ul>
      </Archive>
    </Layout>
  );
};

export const query = graphql`
  query AllNotesQuery {
    prismic {
      allNotes(sortBy: publication_date_DESC) {
        edges {
          cursor
          node {
            title
            content
            _meta {
              type
              uid
            }
            publication_date
          }
        }
      }
    }
  }
`;
